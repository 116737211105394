import React, { FC, useContext, useEffect, useState } from 'react'
import * as pageUtils from './__index.utils'
import { AppStateContext } from '@components/layouts/DynamicLayout'
import { joinClasses } from '@utils/styles'
import { Request, RequestConsent } from '@services/models'
import {
	IDENTIFIERS_OF_TN_REQUEST_TYPE,
	INVOICE_STATUS,
	RequestStatusEnum
} from '@services/constants'
import { getStatusBadgeLabel } from '../requestCard'
import { MODAL } from '@pages/auth/requests/__[id].utils'
import HistoryCard from '../historyCard'
import { formatHtmlForStrapiText, formatStrapiText } from '@utils/methods'
import Notification from '@components/ui/notification'
import Attachments from '../Attachment'
import Consent from '../consent'
import AddInformationModal from '../addInformationModal'
import CancelRequestModal from '../cancelRequestModal'
import { Icon } from '../Icon'
import { cancelWhiteIcon, pendingBlueIcon, plusWhiteIcon } from '@images/icons'
import IncidentFormDetails from '../incidentFormDetails'
import Collapse from '../collapse'
import RedirectModal from '../modals/redirectModal'
import Invoice from '../invoice'
import { navigate } from 'gatsby'
import config from '@utils/config'
import Text from '../text'
import moment from 'moment'
import StatusBadge from '../statusBadge'
import Tooltip from '../tooltip'

type propsType = {
	request: Request
	isModalOpen: string
	consentList: RequestConsent[]
	consentClassWrapper: string
	onAddNewConsent: (consent: RequestConsent) => void
	onCloseModal: () => void
	onToggleModal: (value: string) => void
	onInformationAdded: (consentId: any) => void
}

type stateType = {
	isPaymentSuccessful?: boolean
	code?: string
	hasInvoice?: boolean
}

const TNRequestDetails: FC<propsType> = ({
	request: requestInfo,
	consentList,
	consentClassWrapper,
	onCloseModal,
	isModalOpen,
	onInformationAdded,
	onAddNewConsent,
	onToggleModal
}) => {
	const state = window.history.state as stateType
	const [isInformationSent, setIsInformationSent] = useState<boolean>(false)
	const [addDocumentsType, setAddDocumentsType] = useState<boolean>(false)
	const [redirect, setRedirect] = useState<boolean>(false)
	const { pageData, authUser, language } = useContext(AppStateContext)
	const [isPaymentSuccessfull, setIsPaymentSuccessfull] = useState<
		boolean | null
	>(null)
	const [paymentMessageCode, setPaymentMessageCode] = useState<string>()
	const [request, setRequest] = useState<Request>(requestInfo)
	const [isInProgress, setIsInProgress] = useState<boolean>(false)

	const MAX_PAYMENT_AMOUNT = 10000

	const PaymentErrorMessage = {
		101: pageData.assets
			?.myRequest_information_the_transaction_was_not_completed_101,
		102: pageData.assets
			?.myRequest_information_the_transaction_was_not_completed_102,
		103: pageData.assets
			?.myRequest_information_the_transaction_was_not_completed_103
	}

	const getFileTitle = (subject: string): string => {
		let labelKey: string = ''
		const number = subject.match(/#\d/)?.[0][1]
		const label = subject?.split(/\d$/)[0].trim()
		Object.keys(pageData.allAssets).forEach((key) => {
			const tempLabelKey = Object.keys(pageData.allAssets[key]).find(
				(el) => pageData.allAssets[key][el].trim() == label
			)
			if (tempLabelKey) {
				labelKey = tempLabelKey
			}
		})
		const title = pageData?.assets?.[labelKey]

		return title ? `${title}${number ?? ''}` : subject
	}

	const hasFileList = (): boolean => {
		const requestsWithFileList = [
			IDENTIFIERS_OF_TN_REQUEST_TYPE.ANNUAL_PERMIT_AND_TAG_FOR_ANIMALS,
			IDENTIFIERS_OF_TN_REQUEST_TYPE.PARKING_PERMIT
		] as string[]

		return requestsWithFileList.includes(request.typeId)
	}

	const getAnimalFileTitle = (subject: string): string => {
		// Expression régulière du format "Nom_Animal #No_Animal - Type_document"
		let regex: RegExp = /^(.*?) #(\d+) - (.*)/

		if (regex.test(subject)) {
			let match: RegExpMatchArray | null = subject.match(regex)

			if (match) {
				return match[1] + ' #' + match[2] + ' - ' + getFileTitle(match[3])
			}
		}

		return getFileTitle(subject)
	}

	const groupBy = function (xs, key) {
		return xs.reduce(function (rv, x) {
			;(rv[x[key]] = rv[x[key]] || []).push(x)
			return rv
		}, {})
	}

	const displayAttachements = () => {
		let attachments: any = []
		Object.entries(
			groupBy(
				request.numericTransactions.filter((tn) => !!tn.attachments?.length),
				'subject'
			)
		).forEach(([key, value]) => {
			attachments.push(
				<div key={`${key}`}>
					<p style={{ marginBottom: '5px' }}>
						<b>{hasFileList() ? getAnimalFileTitle(key) : getFileTitle(key)}</b>
					</p>

					{getAttachements(value)}
				</div>
			)
		})

		return attachments
	}

	const getAttachements = (files) => {
		return files.map((tn, key) => (
			<Attachments
				noTitle
				showHeader
				files={tn.attachments!}
				classWrapper={pageUtils.classes.attachments}
			/>
		))
	}

	const isPublicAuctionForm = (request: Request) => {
		return request.typeId == IDENTIFIERS_OF_TN_REQUEST_TYPE.PUBLIC_AUCTIONS
	}

	const getTitleDetailSection = (request: Request) => {
		switch (request.typeId) {
			case IDENTIFIERS_OF_TN_REQUEST_TYPE.PUBLIC_AUCTIONS:
				return pageData.assets?.request_form_registration_details

			case IDENTIFIERS_OF_TN_REQUEST_TYPE.CLAIM_NOTICE:
				return pageData.assets?.request_form_claim_details

			case IDENTIFIERS_OF_TN_REQUEST_TYPE.OIL_HEATING_DECLARATION_FORM:
				return pageData.assets?.request_form_divulgation_details

			case IDENTIFIERS_OF_TN_REQUEST_TYPE.HISTORICAL_ARCHIVE_FORM:
			case IDENTIFIERS_OF_TN_REQUEST_TYPE.BUILDING_TRANSACTION_REQUEST:
			case IDENTIFIERS_OF_TN_REQUEST_TYPE.TEMPORARY_PESTICIDE_APPLICATION_PERMIT_FOR_INDIVIDUALS_FORM:
			case IDENTIFIERS_OF_TN_REQUEST_TYPE.ANNUAL_REGISTRATION_CERTIFICATE_FOR_CONTRACTORS:
			case IDENTIFIERS_OF_TN_REQUEST_TYPE.FIRE_HYDRANTS_TANK_FILLING_FORM:
			case IDENTIFIERS_OF_TN_REQUEST_TYPE.FIRE_HYDRANTS_TEMP_AQUEDUCT_NETWORK_FORM:
			case IDENTIFIERS_OF_TN_REQUEST_TYPE.HYDRAULIC_AND_PRESSURE_TESTS:
			case IDENTIFIERS_OF_TN_REQUEST_TYPE.ANNUAL_PERMIT_AND_TAG_FOR_ANIMALS:
			case IDENTIFIERS_OF_TN_REQUEST_TYPE.MUNICIPAL_EVALUATION_FORM:
			case IDENTIFIERS_OF_TN_REQUEST_TYPE.PERMIT_OCCUPATION_OF_PUBLIC_HIGHWAY:
			case IDENTIFIERS_OF_TN_REQUEST_TYPE.PARKING_PERMIT:
				// Détails de la demande
				return pageData.assets?.request_form_request_details
			case IDENTIFIERS_OF_TN_REQUEST_TYPE.FIREPLACE_DECLARATION:
			case IDENTIFIERS_OF_TN_REQUEST_TYPE.SELF_RELIEF_WATER_COUNTER_FORM:
				// Détails de la déclaration
				return pageData.assets?.request_form_statement_details

			default:
				return pageData.assets?.request_form_grant_details
		}
	}

	// Fonction pour les use cases dont le payement n'est pas necessaire
	// actuelement juste pour ne pas afficher le bloc de payement et recp de payement
	// se trouve à deux emplacements: TNRequestDetails et requestCard
	const isParticularConditionsOfNoPaymentRequired = () => {
		if (
			[
				IDENTIFIERS_OF_TN_REQUEST_TYPE.FIRE_HYDRANTS_TANK_FILLING_FORM,
				IDENTIFIERS_OF_TN_REQUEST_TYPE.FIRE_HYDRANTS_TEMP_AQUEDUCT_NETWORK_FORM,
				IDENTIFIERS_OF_TN_REQUEST_TYPE.HYDRAULIC_AND_PRESSURE_TESTS
			].includes(request?.typeId as IDENTIFIERS_OF_TN_REQUEST_TYPE) &&
			request?.incidentForm?.isContractWithCity == true
		) {
			return true
		}

		return false
	}

	useEffect(() => {
		if (
			request?.invoice?.status == INVOICE_STATUS.paid &&
			state?.isPaymentSuccessful
		) {
			setIsPaymentSuccessfull(true)
		} else if (
			request?.invoice?.status != INVOICE_STATUS.paid &&
			state?.isPaymentSuccessful == false
		) {
			setIsPaymentSuccessfull(false)
			setPaymentMessageCode(state.code)
		}
	}, [request])

	const fetchRequest = async () => {
		const requestsList = await authUser?.fetchRequests(false)
		const newRequest = requestsList?.find((req) => req.id == request?.id)
		if (newRequest && newRequest.invoice?.status === INVOICE_STATUS.paid) {
			const requestTmp = await authUser?.getRequest(request.id!)
			await requestTmp?.fetchPartialsAsync()
			let updatedRequest = newRequest
			updatedRequest.timeline = requestTmp?.timeline ?? []

			setRequest(updatedRequest)
			removePaymentMessage()
			setIsInProgress(false)
		}
	}

	useEffect(() => {
		if (isPaymentInProgress()) {
			setPaymentMessage()
			setIsInProgress(true)
		}

		if (getPaymentMessage() || state?.hasInvoice) {
			setTimeout(() => {
				fetchRequest()
			}, 8000)
		}
		return () => {
			removePaymentMessage()
		}
	}, [])

	const getPaymentMessage = () => {
		return localStorage.getItem(config.localStorage.paymentMessageKey)
	}
	const removePaymentMessage = () => {
		localStorage.removeItem(config.localStorage.paymentMessageKey)
	}

	const setPaymentMessage = () => {
		if (getPaymentMessage() == null) {
			localStorage.setItem(config.localStorage.paymentMessageKey, 'true')
		}
	}

	const isPaymentInProgress = () => {
		if (
			state?.isPaymentSuccessful &&
			request.invoice?.status != INVOICE_STATUS.paid
		) {
			return true
		}
		return false
	}

	const displayPaymentInfo = (): boolean => {
		if (isPaymentSuccessfull === null) {
			return false
		} else {
			return true
		}
	}

	const getPaymentErrorMsg = () => {
		if (paymentMessageCode) {
			const errorCode = parseInt(paymentMessageCode)
			const errorMessage = PaymentErrorMessage[errorCode]
			if (errorMessage) {
				return pageData.assets?.myRequest_information_the_payment_was_unsuccessful.replace(
					'****',
					errorMessage
				)
			}
			return pageData.assets
				?.myRequest_information_the_transaction_was_not_completed
		}
		return pageData.assets
			?.myRequest_information_the_transaction_was_not_completed
	}

	useEffect(() => {
		addTargetBlankToLink()
	}, [])

	const addTargetBlankToLink = () => {
		const linksContainer = document.getElementsByClassName('link-redirect')
		Array.prototype.forEach.call(linksContainer, (container: HTMLElement) => {
			const links = container.getElementsByTagName('a')
			Array.prototype.forEach.call(links, (link) => {
				link.setAttribute('target', '_blank')
				if (process.env.PAYMENT_POLICY_URL)
					link.setAttribute('href', process.env.PAYMENT_POLICY_URL)
			})
		})
	}

	const convertedDate = (date: string) => {
		const utcDateTime = moment.utc(date)
		const localDateTime = utcDateTime.local()

		return localDateTime.format('YYYY-MM-DD HH:mm')
	}

	const getDelayValue = () => {
		if (request?.resolutionDays) {
			return request?.resolutionDays
		}
		return request?.actionDays
	}

	const shouldDelayBeDisplayed = () => {
		const limitDate = moment(
			'2024-12-01T00:00:00.000Z',
			'YYYY-MM-DDTHH:mm:ss.SSSZ'
		)

		return moment(request?.dateAdd?.ISODateString).isAfter(limitDate)
	}

	const getTooltipLabel = () => {
		if (request?.resolutionDays) {
			return pageData?.assets?.request_form_resolution_delay
		}
		return pageData?.assets?.request_form_action_delay
	}

	return (
		<>
			{redirect && (
				<RedirectModal
					shouldRedirect={redirect}
					onRedirect={() => {
						navigate('/auth/payment', {
							state: {
								invoice: request.invoice,
								origin: location.pathname
							}
						})
					}}
					text={
						pageData?.assets
							?.redirectModal_you_will_be_redirected_to_the_payment_method
					}
				/>
			)}
			<div className={''}>
				<div className={pageUtils.classes.messageContainer}>
					{!isInProgress &&
						request?.invoice?.status == INVOICE_STATUS.waitingForPayment && (
							<Notification
								text={
									request.invoice?.grandTotal! > MAX_PAYMENT_AMOUNT
										? pageData.assets
												?.myRequest_information_payment_maximum_limit
										: pageData.assets?.myRequest_information_waiting_for_payment
								}
								type="warning"
								hasHtml
							/>
						)}

					{isInProgress && (
						<Notification
							text={
								pageData.assets
									?.myRequest_information_the_payment_is_in_progress
							}
							type="info"
							hasHtml
							onClickCancelBtn={() => {
								setIsPaymentSuccessfull(null)
							}}
							showSpinner
						/>
					)}
					{displayPaymentInfo() && (
						<Notification
							showExitBtn
							text={
								isPaymentSuccessfull
									? `${
											pageData.assets
												?.myRequest_information_the_payment_has_been_made_successfully
									  } <b> ${
											request.invoice?.transactionReferenceNumber ?? ''
									  }</b>`
									: getPaymentErrorMsg()
							}
							type={isPaymentSuccessfull ? 'success' : 'error'}
							hasHtml
							onClickCancelBtn={() => {
								setIsPaymentSuccessfull(null)
							}}
						/>
					)}
					{isInformationSent && (
						<Notification
							showExitBtn
							text={`${pageData.assets?.myRequest_information_well_added_to_the_request} <b>${request?.code}</b>`}
							type="success"
							hasHtml
							onClickCancelBtn={() => {
								setIsInformationSent(false)
							}}
						/>
					)}
				</div>

				<div className={pageUtils.classes.container}>
					<div className={pageUtils.classes.section}>
						<div
							className={joinClasses([
								pageUtils.classes.bloc,
								pageUtils.classes.borderBottom,
								pageUtils.classes.noMarginBottom
							])}
						>
							<div className={pageUtils.classes.actionRow}>
								{/* <div className={pageUtils.classes.status}>
									<div
										className={`${pageUtils.classes.tag} ${
											pageUtils.classes[
												request?.cancelReason
													? RequestStatusEnum.CLOSED
													: request?.status ?? RequestStatusEnum.PROCESSING
											]
										}`}
									>
										{getStatusBadgeLabel(
											pageData.assets,
											request?.cancelReason
												? RequestStatusEnum.CLOSED
												: request?.status ?? RequestStatusEnum.PROCESSING
										)}
									</div>
								</div> */}
								<span className={pageUtils.classes.removePadding}>
									<StatusBadge request={request} pageAssets={pageData.assets} />
								</span>

								<div className={pageUtils.classes.actionButtons}>
									<button
										className={joinClasses([
											pageUtils.classes.button,
											pageUtils.classes.buttonWithIcon
										])}
										onClick={() => onToggleModal(MODAL.ADD_INFORMATION)}
									>
										<Icon src={plusWhiteIcon} />
										{formatStrapiText(
											pageData?.assets?.page_requestDetails_add_an_information
										)}
									</button>
									{!request?.cancelReason &&
										request?.status !== RequestStatusEnum.CLOSED && (
											<button
												className={joinClasses([
													pageUtils.classes.button,
													pageUtils.classes.buttonWithIcon
												])}
												onClick={() => onToggleModal(MODAL.CANCEL)}
											>
												<Icon src={cancelWhiteIcon} />
												{formatStrapiText(pageData?.assets?.button_cancel)}
											</button>
										)}
								</div>
							</div>

							<div
								style={{ marginTop: '1.5rem' }}
								className={pageUtils.classes.informationLine}
							>
								<span className={pageUtils.classes.infoQuestion}>
									<b>
										{formatStrapiText(
											pageData?.assets
												?.request_form_confirmation_request_number_text
										)}
									</b>
								</span>
								<span className={pageUtils.classes.infoAnswer}>
									{request.code}
								</span>
							</div>
							<div className={pageUtils.classes.informationLine}>
								<span className={pageUtils.classes.infoQuestion}>
									<b>
										{formatStrapiText(pageData?.assets?.label_creationDate)}
									</b>
								</span>
								<span className={pageUtils.classes.infoAnswer}>
									{request?.dateAdd?.localDateAndTimeString}
								</span>
							</div>

							<div className={pageUtils.classes.informationLine}>
								{shouldDelayBeDisplayed() && (
									<>
										<span className={pageUtils.classes.infoQuestion}>
											<b>
												{formatStrapiText(
													pageData?.assets?.normed_delays_treatment_label
												)}
											</b>
											{getDelayValue() && (
												<div className={pageUtils.classes.infoTooltip}>
													<Tooltip
														showInfo
														infoLabel={getTooltipLabel()}
														isModal={false}
													/>
												</div>
											)}
										</span>
										<span className={pageUtils.classes.infoAnswer}>
											{getDelayValue()
												? getDelayValue() + ' ' + pageData?.assets?.label_days
												: pageData?.assets.normed_delays_no_subject_label}
										</span>
									</>
								)}
							</div>
						</div>

						<div
							className={joinClasses([
								pageUtils.classes.bloc,
								pageUtils.classes.historyCard
							])}
						>
							<HistoryCard
								history={request?.timeline}
								requestCreationDate={request.dateAdd}
							/>
						</div>
					</div>

					<div className={pageUtils.classes.section}>
						{request.invoice &&
							request.invoice.status !== INVOICE_STATUS.paid &&
							request.invoice.status !== INVOICE_STATUS.notRequired &&
							!isPaymentInProgress() &&
							// Test pour les use cases dont le payement n'est pas necessaire
							!isParticularConditionsOfNoPaymentRequired() && (
								<div className={pageUtils.classes.bloc}>
									<h3 className={pageUtils.classes.h3}>
										{formatStrapiText(
											pageData?.assets?.page_requestDetails_payment_to_be_made
										)}
									</h3>

									{request.invoice.status ===
										INVOICE_STATUS.calculationOfTheAmount && (
										<Notification type="info" text="">
											<p className={pageUtils.classes.notificationText}>
												<Icon src={pendingBlueIcon} />
												<span>
													{pageData?.assets?.page_requestDetails_payment_info}
												</span>
											</p>
										</Notification>
									)}

									<Invoice invoice={request.invoice} />

									{request.invoice.status ===
										INVOICE_STATUS.waitingForPayment &&
										request.invoice?.grandTotal! <= MAX_PAYMENT_AMOUNT && (
											<>
												<p
													className={joinClasses([
														pageUtils.classes.description,
														'link-redirect'
													])}
												>
													{
														<Text
															content={
																pageData.assets
																	?.page_requestDetails_consult_payment_policy
															}
														/>
													}
												</p>
												<div className={pageUtils.classes.buttonContainer}>
													<button
														className={pageUtils.classes.button}
														onClick={() => {
															removePaymentMessage()
															setRedirect(true)
														}}
													>
														{pageData?.assets?.label_pay_now}
													</button>
												</div>
											</>
										)}
								</div>
							)}

						<div className={pageUtils.classes.bloc}>
							<h3 className={pageUtils.classes.h3}>
								{formatStrapiText(pageData?.assets?.myRequest_request_summary)}
							</h3>

							<div className={pageUtils.classes.collapse}>
								<Collapse
									id={1}
									buttonText={formatStrapiText(
										pageData.assets?.request_form_personal_informations
									)}
								>
									<>
										<p className={pageUtils.classes.paragraph}>
											<b>
												{' '}
												{formatStrapiText(
													pageData.assets?.myRequest_collapse_full_name
												)}{' '}
											</b>
											<br />
											{request.incidentForm?.applicant?.firstName}{' '}
											{request.incidentForm?.applicant?.lastName}
										</p>

										<p className={pageUtils.classes.paragraph}>
											<b> {formatStrapiText(pageData.assets?.label_email)} </b>
											<br />
											{request.incidentForm?.applicant?.email}
										</p>

										<p className={pageUtils.classes.paragraph}>
											<b>
												{' '}
												{formatStrapiText(pageData.assets?.label_address)}{' '}
											</b>
											<br />
											{request.incidentForm?.applicant?.address}
											<br />
											{request.incidentForm?.applicant?.city}{' '}
											{/* // @TODO: ajouter `state` dans l'objet IApplicant  */}
											{/* {' (QC) '}´´ */}
											{!!request.incidentForm?.applicant?.postalCode &&
												request.incidentForm?.applicant?.postalCode !==
													'none' &&
												request.incidentForm?.applicant?.postalCode}
											<br />
											{request.incidentForm?.applicant?.apartment
												? `${formatStrapiText(
														pageData.assets?.label_apartment_office
												  )} ${request.incidentForm?.applicant?.apartment}`
												: ''}
										</p>

										<p className={pageUtils.classes.paragraph}>
											<b>
												{' '}
												{formatStrapiText(
													pageData.assets?.label_telephone
												)}{' '}
											</b>
											<br />
											{request.incidentForm?.applicant?.telephone ||
												request.incidentForm?.applicant?.telephone_evening ||
												request.incidentForm?.applicant?.telephone_other}
										</p>
									</>
								</Collapse>

								<Collapse
									id={2}
									buttonText={formatStrapiText(getTitleDetailSection(request))}
								>
									<IncidentFormDetails
										pageAssets={pageData.assets}
										request={request}
										requests={pageData.requests}
										language={language!}
									/>
								</Collapse>
								{request.invoice &&
									request.invoice.status == INVOICE_STATUS.paid &&
									// Test pour les use cases dont le payement n'est pas necessaire
									!isParticularConditionsOfNoPaymentRequired() && (
										<>
											<Collapse
												id={2}
												buttonText={formatStrapiText(
													pageData.assets?.label_payment
												)}
											>
												<>
													{request.invoice?.transactionReferenceNumber && (
														<p className={pageUtils.classes.paragraph}>
															<b>
																{' '}
																{formatStrapiText(
																	pageData.assets
																		?.page_requestDetails_confirmation_number
																)}{' '}
															</b>
															<br />
															{request.invoice?.transactionReferenceNumber}
														</p>
													)}

													{request.invoice?.paymentDate && (
														<p className={pageUtils.classes.paragraph}>
															<b>
																{' '}
																{formatStrapiText(
																	pageData.assets
																		?.page_requestDetails_payment_date
																)}{' '}
															</b>
															<br />
															{convertedDate(request.invoice?.paymentDate)}
														</p>
													)}

													{!!request.invoice?.grandTotal && (
														<p className={pageUtils.classes.paragraph}>
															<b>
																{' '}
																{formatStrapiText(
																	pageData.assets
																		?.page_requestDetails_payroll_amount
																)}{' '}
															</b>
															<br />
															{request.invoice?.grandTotal
																?.toFixed(2)
																.replace('.', ',')}
															$
														</p>
													)}
												</>
											</Collapse>
										</>
									)}

								{request.invoice &&
									request.invoice.status == INVOICE_STATUS.notRequired && (
										<>
											<Collapse
												id={3}
												buttonText={formatStrapiText(
													pageData.assets?.label_payment_not_required
												)}
											>
												<>
													<p className={pageUtils.classes.paragraph}>
														<b>
															{formatStrapiText(
																pageData.assets
																	?.page_requestDetails_payment_not_required_description
															)}
														</b>
														<br />
													</p>
												</>
											</Collapse>
										</>
									)}
							</div>
						</div>

						<div className={pageUtils.classes.bloc}>
							<div className={pageUtils.classes.actionTitle}>
								<h3 className={pageUtils.classes.h3}>
									{formatStrapiText(
										pageData?.assets?.page_requestDetails_documents
									)}
								</h3>
								<button
									className={pageUtils?.classes.button}
									onClick={() => {
										onToggleModal(MODAL.ADD_INFORMATION)
										setAddDocumentsType(true)
									}}
								>
									{formatStrapiText(
										pageData?.assets?.page_requestDetails_add_a_document
									)}
								</button>
							</div>

							{displayAttachements()}
						</div>

						<div
							className={joinClasses([
								pageUtils.classes.bloc,
								pageUtils.classes.clearInnerPadding
							])}
						>
							<Consent
								consentList={consentList}
								onAddNewConsent={onAddNewConsent}
								className={consentClassWrapper}
							/>
						</div>
					</div>
				</div>

				<AddInformationModal
					setModal={() => {
						onCloseModal()
						setAddDocumentsType(false)
					}}
					request={request!}
					isOpen={isModalOpen === MODAL.ADD_INFORMATION}
					onInformationAdded={onInformationAdded}
					addDocumentsType={addDocumentsType}
				/>
				<CancelRequestModal
					onCancel={onCloseModal}
					isOpen={isModalOpen == MODAL.CANCEL}
					request={request}
					title={
						isPublicAuctionForm(request)
							? pageData?.assets?.request_form_cancel_registration
							: undefined
					}
					description={
						isPublicAuctionForm(request)
							? pageData?.assets.request_form_publicAuctions_cancel_registration
							: undefined
					}
					isDefaultCancelReason={isPublicAuctionForm(request)}
				/>
			</div>
		</>
	)
}

export default TNRequestDetails
