import React, { FC, useState } from 'react'
import * as pageUtils from './__details.utils'
import { REQUEST_CONFIGS_KEYS } from '@services/constants'
import { RadioList, SelectList } from '@utils/request'
import { formatStrapiText } from '@utils/methods'
import TableDetailsModal from '../modals/tableDetailsModal'
import Text from '@components/ui/text'

const SwimmingPoolSpaPermitFormDetails: FC<pageUtils.Props> = ({
	pageAssets,
	request
}) => {
	const radioListWhatToDoSwimmingPoolSpaPermit: RadioList[] = [
		{
			label:
				pageAssets?.request_form_swimmingPoolSpaPermit_request_installation,
			value:
				REQUEST_CONFIGS_KEYS.whatToDoSwimmingPoolSpaPermit.request_installation,
			key: 'request_form_swimmingPoolSpaPermit_request_installation'
		},
		{
			label: pageAssets?.request_form_swimmingPoolSpaPermit_request_upgrading,
			value:
				REQUEST_CONFIGS_KEYS.whatToDoSwimmingPoolSpaPermit.request_upgrading,
			key: 'request_form_swimmingPoolSpaPermit_request_upgrading'
		}
	]

	const radioListRequestAs: RadioList[] = [
		{
			label: pageAssets?.request_form_individual,
			value: REQUEST_CONFIGS_KEYS.requestAs.individual,
			key: 'request_form_individual'
		},
		{
			label: pageAssets?.request_form_entreprise,
			value: REQUEST_CONFIGS_KEYS.requestAs.entreprise,
			key: 'request_form_entreprise'
		}
	]

	const radioListYesNo: RadioList[] = [
		{
			label: pageAssets.request_form_select_yes,
			value: 'true',
			key: 'request_form_select_yes'
		},
		{
			label: pageAssets.request_form_select_no,
			value: 'false',
			key: 'request_form_select_no'
		}
	]

	const radioListInstallationType: RadioList[] = [
		{
			label: '',
			value: '',
			key: ''
		},
		{
			label:
				pageAssets?.request_form_swimmingPoolSpaPermit_installationType_option1,
			value:
				REQUEST_CONFIGS_KEYS.installationTypeSwimmingPoolSpaPermit.firstOption,
			key: 'request_form_swimmingPoolSpaPermit_installationType_option1'
		},
		{
			label:
				pageAssets?.request_form_swimmingPoolSpaPermit_installationType_option2,
			value:
				REQUEST_CONFIGS_KEYS.installationTypeSwimmingPoolSpaPermit.secondOption,
			key: 'request_form_swimmingPoolSpaPermit_installationType_option2'
		},
		{
			label:
				pageAssets?.request_form_swimmingPoolSpaPermit_installationType_option3,
			value:
				REQUEST_CONFIGS_KEYS.installationTypeSwimmingPoolSpaPermit.thirdOption,
			key: 'request_form_swimmingPoolSpaPermit_installationType_option3'
		},
		{
			label:
				pageAssets?.request_form_swimmingPoolSpaPermit_installationType_option4,
			value:
				REQUEST_CONFIGS_KEYS.installationTypeSwimmingPoolSpaPermit.fourthOption,
			key: 'request_form_swimmingPoolSpaPermit_installationType_option4'
		}
	]

	const POOL_SHAPE_REQUIRED_OPTIONS = [
		REQUEST_CONFIGS_KEYS.installationTypeSwimmingPoolSpaPermit.firstOption,
		REQUEST_CONFIGS_KEYS.installationTypeSwimmingPoolSpaPermit.secondOption,
		REQUEST_CONFIGS_KEYS.installationTypeSwimmingPoolSpaPermit.thirdOption
	]

	const radioListMeasurementUnit: RadioList[] = [
		{
			label: pageAssets?.request_form_meters,
			value: REQUEST_CONFIGS_KEYS.measurement_unit.meters_unit,
			key: 'request_form_meters'
		},
		{
			label: pageAssets?.request_form_foot,
			value: REQUEST_CONFIGS_KEYS.measurement_unit.foot_unit,
			key: 'label_kg'
		}
	]

	const radioListPoolShape: RadioList[] = [
		{
			label: pageAssets?.request_form_swimmingPoolSpaPermit_poolShape_option1,
			value: REQUEST_CONFIGS_KEYS.poolShape.firstOption,
			key: 'request_form_swimmingPoolSpaPermit_poolShape_option1'
		},
		{
			label: pageAssets?.request_form_swimmingPoolSpaPermit_poolShape_option2,
			value: REQUEST_CONFIGS_KEYS.poolShape.secondOption,
			key: 'request_form_swimmingPoolSpaPermit_poolShape_option2'
		},
		{
			label: pageAssets?.request_form_swimmingPoolSpaPermit_poolShape_option3,
			value: REQUEST_CONFIGS_KEYS.poolShape.thirdOption,
			key: 'request_form_swimmingPoolSpaPermit_poolShape_option3'
		}
	]

	const protectiveEnclosureTypeList: RadioList[] = [
		{
			label:
				pageAssets?.select_swimmingPoolSpaPermit_protectiveEnclosureType_firstOption,
			value: REQUEST_CONFIGS_KEYS.protectiveEnclosureType.firstOption,
			key: 'select_swimmingPoolSpaPermit_protectiveEnclosureType_firstOption'
		},
		{
			label:
				pageAssets?.select_swimmingPoolSpaPermit_protectiveEnclosureType_secondOption,
			value: REQUEST_CONFIGS_KEYS.protectiveEnclosureType.secondOption,
			key: 'select_swimmingPoolSpaPermit_protectiveEnclosureType_secondOption'
		},
		{
			label:
				pageAssets?.select_swimmingPoolSpaPermit_protectiveEnclosureType_thirdOption,
			value: REQUEST_CONFIGS_KEYS.protectiveEnclosureType.thirdOption,
			key: 'select_swimmingPoolSpaPermit_protectiveEnclosureType_thirdOption'
		},
		{
			label:
				pageAssets?.select_swimmingPoolSpaPermit_protectiveEnclosureType_fourthOption,
			value: REQUEST_CONFIGS_KEYS.protectiveEnclosureType.fourthOption,
			key: 'select_swimmingPoolSpaPermit_protectiveEnclosureType_fourthOption'
		},
		{
			label:
				pageAssets?.select_swimmingPoolSpaPermit_protectiveEnclosureType_fifthOption,
			value: REQUEST_CONFIGS_KEYS.protectiveEnclosureType.fifthOption,
			key: 'select_swimmingPoolSpaPermit_protectiveEnclosureType_fifthOption'
		},
		{
			label:
				pageAssets?.select_swimmingPoolSpaPermit_protectiveEnclosureType_sixthOption,
			value: REQUEST_CONFIGS_KEYS.protectiveEnclosureType.sixthOption,
			key: 'select_swimmingPoolSpaPermit_protectiveEnclosureType_sixthOption',
			details: <></>
		}
	]

	// const getPoolShapeImage = (value) => {
	// 	switch (value) {
	// 		case REQUEST_CONFIGS_KEYS.poolShape.firstOption:
	// 			return poolForme1
	// 		case REQUEST_CONFIGS_KEYS.poolShape.secondOption:
	// 			return poolForme2
	// 		case REQUEST_CONFIGS_KEYS.poolShape.thirdOption:
	// 			return poolForme3
	// 	}
	// }

	const getLabelFromList = (list: SelectList[], value) => {
		return list?.find((element) => element.value == value?.toString())?.label
	}

	return (
		<>
			{/* details 1 */}
			<h4 className={pageUtils.classes.h4}>
				{pageAssets.request_form_type_de_demandes}
			</h4>

			<p className={pageUtils.classes.paragraph}>
				<b>{pageAssets.request_form_what_would_you_like_to_do}</b>
				<br />
				{getLabelFromList(
					radioListWhatToDoSwimmingPoolSpaPermit,
					request.incidentForm?.whatToDo
				)?.replace(/\*\*/g, '')}
			</p>

			<p className={pageUtils.classes.paragraph}>
				<b>{pageAssets.request_form_request_as}</b>
				<br />
				{getLabelFromList(radioListRequestAs, request.incidentForm?.requestAs)}
			</p>

			{request.incidentForm?.requestAs?.toString() ==
				REQUEST_CONFIGS_KEYS.requestAs.entreprise && (
				<>
					<p className={pageUtils.classes.paragraph}>
						<b>
							{
								pageAssets.request_form_annualRegistrationCertificateForContractors_companyName_label
							}
						</b>
						<br />
						{request.incidentForm?.companyName}
					</p>

					<p className={pageUtils.classes.paragraph}>
						<b>
							{
								pageAssets.request_form_swimmingPoolSpaPermit_representativeName_label
							}
						</b>
						<br />
						{request.incidentForm?.representativeName}
					</p>
				</>
			)}

			{/* --------------------------- */}
			{/* details 2 */}
			{/* ---------------------------------------- */}

			<h4 className={pageUtils.classes.h4}>
				{pageAssets.request_form_request_location}
			</h4>

			<p className={pageUtils.classes.paragraph}>
				<b>{pageAssets.request_form_swimmingPoolSpaPermit_isSameLocation}</b>
				<br />
				{getLabelFromList(
					radioListYesNo,
					request.incidentForm?.sameAddressAsApplicant
				)}
			</p>

			{request.incidentForm?.sameAddressAsApplicant?.toString() ==
				radioListYesNo[1].value && (
				<>
					<p className={pageUtils.classes.paragraph}>
						<b>{pageAssets.request_form_location}</b>
						<br />
						{request.incidentForm?.addressField}
						<br />
						{request.incidentForm?.addressLotNumber
							? pageAssets?.label_cadastral_number +
							  ': ' +
							  request.incidentForm?.addressLotNumber
							: ''}
					</p>
				</>
			)}

			<p className={pageUtils.classes.paragraph}>
				<b>{pageAssets.request_form_swimmingPoolSpaPermit_isLocationOwned}</b>
				<br />
				{getLabelFromList(
					radioListYesNo,
					request.incidentForm?.isLocationOwned
				)}
			</p>

			{/* ****** Owner inputs ****** */}
			{request.incidentForm?.isLocationOwned?.toString() ==
				radioListYesNo[1].value && (
				<span className={pageUtils.classes.paragraph}>
					<div>
						<strong>{pageAssets.label_firstName}</strong>
						<br />

						{request.incidentForm?.buildingOwnerInfo?.first_name}
					</div>
					<div>
						<strong>{pageAssets.label_lastName}</strong>
						<br />

						{request.incidentForm?.buildingOwnerInfo?.last_name}
					</div>
					<div>
						<strong>{pageAssets.label_email}</strong>
						<br />

						{request.incidentForm?.buildingOwnerInfo?.email}
					</div>
					<div>
						<strong>{pageAssets.label_telephone}</strong>
						<br />

						{request.incidentForm?.buildingOwnerInfo?.telephone}
					</div>
					<br />
				</span>
			)}

			<p className={pageUtils.classes.paragraph}>
				<b>
					{
						pageAssets.request_form_swimmingPoolSpaPermit_isPropertyJointOwnership
					}
				</b>
				<br />
				{getLabelFromList(
					radioListYesNo,
					request.incidentForm?.isPropertyJointOwnership
				)}
			</p>

			<p className={pageUtils.classes.paragraph}>
				<b>
					{
						pageAssets.request_form_swimmingPoolSpaPermit_isPropertyLocatedOnWaterfront
					}
				</b>
				<br />
				{getLabelFromList(
					radioListYesNo,
					request.incidentForm?.isPropertyLocatedOnWaterfront
				)}
			</p>

			{/* --------------------------- */}
			{/* details 3 */}
			{/* ---------------------------------------- */}

			<h4 className={pageUtils.classes.h4}>
				{pageAssets.request_form_swimmingPoolSpaPermit_thirdSubStep_title}
			</h4>

			<p className={pageUtils.classes.paragraph}>
				<b>{pageAssets.request_form_swimmingPoolSpaPermit_installationType}</b>
				<br />
				{getLabelFromList(
					radioListInstallationType,
					request.incidentForm?.installationType
				)}
			</p>

			{request.incidentForm?.installationType?.toString() ==
				REQUEST_CONFIGS_KEYS.installationTypeSwimmingPoolSpaPermit
					.fourthOption && (
				<>
					<div>
						<strong>
							{
								pageAssets.request_form_swimmingPoolSpaPermit_isSpaLitersCondition
							}
						</strong>
						<br />
						{getLabelFromList(
							radioListYesNo,
							request.incidentForm?.isSpaLitersCondition
						)}
					</div>
				</>
			)}

			{POOL_SHAPE_REQUIRED_OPTIONS.includes(
				request.incidentForm?.installationType?.toString() || ''
			) && (
				<>
					<div className={pageUtils.classes.paragraph}>
						<strong>
							{pageAssets.request_form_swimmingPoolSpaPermit_poolShape}
						</strong>
						<br />

						{getLabelFromList(
							radioListPoolShape,
							request.incidentForm?.poolShape
						)}
					</div>
					{request.incidentForm?.whatToDo?.toString() ==
						radioListWhatToDoSwimmingPoolSpaPermit[0].value && (
						<div className={pageUtils.classes.paragraph}>
							{/* Question 10.1 */}
							{/* Diamètre IF shape Ronde */}
							{request.incidentForm?.poolShape?.toString() ==
								radioListPoolShape[0].value && (
								<div>
									<strong>
										{pageAssets.request_form_swimmingPoolSpaPermit_poolDiameter}
									</strong>
									<br />

									{request.incidentForm?.poolDiameter}
									{` `}
									{getLabelFromList(
										radioListMeasurementUnit,
										request.incidentForm?.poolDiameterUnit
									)}
								</div>
							)}

							{[
								radioListPoolShape[1].value,
								radioListPoolShape[2].value
							].includes(request.incidentForm?.poolShape?.toString() || '') && (
								<>
									{/* Question 10.3 */}
									<div>
										<strong>
											{pageAssets.request_form_swimmingPoolSpaPermit_poolWidth}
										</strong>
										<br />

										{request.incidentForm?.poolWidth}
										{` `}
										{getLabelFromList(
											radioListMeasurementUnit,
											request.incidentForm?.poolWidthUnit
										)}
									</div>

									{/* Question 10.4 */}
									<div>
										<strong>
											{pageAssets.request_form_swimmingPoolSpaPermit_poolLength}
										</strong>
										<br />

										{request.incidentForm?.poolLength}
										{` `}
										{getLabelFromList(
											radioListMeasurementUnit,
											request.incidentForm?.poolLengthUnit
										)}
									</div>
								</>
							)}

							{request.incidentForm?.installationType?.toString() ==
								REQUEST_CONFIGS_KEYS.installationTypeSwimmingPoolSpaPermit
									.firstOption && (
								<div>
									<strong>
										{pageAssets.request_form_swimmingPoolSpaPermit_poolHeight}
									</strong>
									<br />

									{request.incidentForm?.poolHeight}
									{` `}
									{getLabelFromList(
										radioListMeasurementUnit,
										request.incidentForm?.poolHeightUnit
									)}
								</div>
							)}
						</div>
					)}

					{/* <img
											src={getPoolShapeImage(request.incidentForm?.poolShape?.toString())}
										/> */}
					{/* </div> */}
				</>
			)}

			{/* ///////// */}

			<h5 className={pageUtils.classes.h5}>
				{pageAssets.request_form_swimmingPoolSpaPermit_installationAndWorks}
			</h5>
			{request.incidentForm?.whatToDo?.toString() ==
				radioListWhatToDoSwimmingPoolSpaPermit[0].value && (
				<>
					<p className={pageUtils.classes.paragraph}>
						<b>
							{
								pageAssets.request_form_swimmingPoolSpaPermit_expectedInstallDate
							}
						</b>
						<br />
						{pageUtils.getLocaleDate(
							request.incidentForm?.expectedInstallDate!
						)}
					</p>

					<p className={pageUtils.classes.paragraph}>
						<b>
							{pageAssets.request_form_swimmingPoolSpaPermit_installationCost}
						</b>
						<br />
						{request.incidentForm?.installationCost} $
					</p>
				</>
			)}

			<p className={pageUtils.classes.paragraph}>
				<b>{pageAssets.request_form_swimmingPoolSpaPermit_isTreeCutRequired}</b>
				<br />
				{getLabelFromList(
					radioListYesNo,
					request.incidentForm?.isTreeCutRequired
				)}
			</p>

			{/* ///////// */}

			{request.incidentForm?.whatToDo?.toString() ==
				radioListWhatToDoSwimmingPoolSpaPermit[0].value && (
				<>
					<h5 className={pageUtils.classes.h5}>
						{
							pageAssets.request_form_swimmingPoolSpaPermit_poolCompanySeller_title
						}
					</h5>

					<p className={pageUtils.classes.paragraph}>
						<b>
							{
								pageAssets.request_form_swimmingPoolSpaPermit_companyPoolName_label
							}
						</b>
						<br />
						{request.incidentForm?.poolSellerInfo?.organization_name}
					</p>

					<p className={pageUtils.classes.paragraph}>
						<b>{pageAssets.label_address}</b>
						<br />
						{request.incidentForm?.poolSellerInfo?.address}
						<br />
						{request.incidentForm?.poolSellerInfo?.city}{' '}
						{!!request.incidentForm?.poolSellerInfo?.postal_code &&
							request.incidentForm?.poolSellerInfo?.postal_code}{' '}
						{/* <br />
				{request.incidentForm?.business?.appartment
					? `${formatStrapiText(pageAssets?.label_apartment_office)} ${
							request.incidentForm?.business?.appartment
					  }`
					: ''} */}
					</p>

					<p className={pageUtils.classes.paragraph}>
						<b>{pageAssets.label_email}</b>
						<br />
						{request.incidentForm?.poolSellerInfo?.email}
					</p>
					<p className={pageUtils.classes.paragraph}>
						<b>{pageAssets.label_telephone}</b>
						<br />
						{request.incidentForm?.poolSellerInfo?.telephone}
					</p>
				</>
			)}

			{/* -------------------------------------------------------- */}
			{/* --------------------------- */}
			{/* details 4 */}
			{/* ---------------------------------------- */}

			<h4 className={pageUtils.classes.h4}>
				{pageAssets.request_form_swimmingPoolSpaPermit_fourthSubStep_title}
			</h4>

			<p className={pageUtils.classes.paragraph}>
				<b>
					{
						pageAssets.request_form_swimmingPoolSpaPermit_protectiveEnclosureType
					}
				</b>
				<br />
				{getLabelFromList(
					protectiveEnclosureTypeList,
					request.incidentForm?.protectiveEnclosureType
				)}
			</p>

			<p className={pageUtils.classes.paragraph}>
				<b>
					{
						pageAssets.request_form_swimmingPoolSpaPermit_protectiveEnclosurePurchaseDate
					}
				</b>
				<br />

				{request.incidentForm?.protectiveEnclosurePurchaseDate
					? pageUtils.getLocaleDate(
							request.incidentForm?.protectiveEnclosurePurchaseDate!
					  )
					: pageAssets?.request_form_swimmingPoolSpaPermit_isProtectiveEnclosureAlreadyInstalled}
			</p>

			<p className={pageUtils.classes.paragraph}>
				<b>
					{
						pageAssets.request_form_swimmingPoolSpaPermit_isProtectiveEnclosureHasWindows
					}
				</b>
				<br />
				{getLabelFromList(
					radioListYesNo,
					request.incidentForm?.isProtectiveEnclosureHasWindows
				)}
			</p>
		</>
	)
}

export default SwimmingPoolSpaPermitFormDetails
