import React, { FC, useContext, useEffect, useState } from 'react'
import { PageTitle } from '@components/configs/PageTitle'
import SectionTitle from '@components/ui/sectionTitle'
import { AppStateContext } from '@components/layouts/DynamicLayout'
import Notification from '@components/ui/notification'
import Loader from '@components/ui/loader'
import {
	CommentsAttachment,
	Request,
	RequestConsent,
	Timeline
} from '@services/models'
import { REQUEST_TYPE } from '@services/constants'
import * as pageUtils from '@pages/auth/requests/__index.utils'
import * as pageRequestDetailsUtils from '@pages/auth/requests/__[id].utils'
import { RequestDetailsPropsType } from '@pages/auth/requests/__[id].utils'
import PlainRequestDetails from '@components/ui/plainRequestDetails'
import TNRequestDetails from '@components/ui/TNRequestDetails'
import RequestInfo from '@components/ui/requestInfo'
import { joinClasses } from '@utils/styles'

const OtherCommentsLabel = 'page_requestInfo_inputDetails'

const RequestDetails: FC<RequestDetailsPropsType> = ({ id: requestId }) => {
	const { pageData, authUser, setUserRequest, userRequest } =
		useContext(AppStateContext)

	const [modal, setModal] = useState<string>('')
	const [request, setRequest] = useState<Request>()
	const [isLoading, setIsLoading] = useState(true)
	const [consentList, setconsentList] = useState<RequestConsent[]>([])
	const [strapiRequest, setStrapiRequest] = useState<any>()
	const [isInformationSent, setIsInformationSent] = useState<boolean>(false)
	const [requestFound, setRequestFound] = useState<boolean>(true)
	const [isOldRequest, setIsOldRequest] = useState<boolean>(true)

	useEffect(() => {
		if (userRequest && userRequest.typeId) {
			const currentRequest = pageData.requests.find(
				(r) =>
					r.requestId === userRequest?.typeId || r.name === userRequest.title
			)

			setStrapiRequest(currentRequest)
		}
		setIsOldRequest(
			new Date(request?.dateAdd?.ISODateString!).getTime() <
				new Date('06/08/2022').getTime()
		)
	}, [userRequest])

	const fetchRequest = async () => {
		if (authUser) {
			try {
				const requestTmp = authUser?.getRequest(requestId)

				if (requestTmp) {
					await requestTmp.fetchPartialsAsync()

					setconsentList(requestTmp.consents ?? [])

					for (let tmpTime of requestTmp.timeline) {
						if (!!tmpTime.activity?.attachments?.length) {
							const comment = requestTmp.comments.find(
								(comment) => comment.id === tmpTime.activity?.id
							)

							if (comment) {
								for (let attachment of tmpTime.activity.attachments) {
									let file = new CommentsAttachment(
										await Timeline.buildFiles(attachment.id)
									)

									attachment.fileContent = file.fileContent
								}
								comment.attachments = tmpTime.activity?.attachments!
							}
						}
					}

					setRequest(requestTmp)
					setUserRequest!(requestTmp)
				} else {
					setRequestFound(false)
				}
			} catch (error: any) {
				console.error(error?.message)
			} finally {
				setTimeout(() => setIsLoading(false), 200)
			}
		}
	}

	useEffect(() => {
		fetchRequest().catch(console.error)
	}, [authUser])

	if (isLoading) {
		return <Loader text={pageData?.assets?.loading} />
	}

	const onAddNewConsent = (consent: RequestConsent) => {
		setconsentList((currentconsentList) => [
			...(currentconsentList ?? []),
			consent
		])
	}

	const onInformationAdded = (consentId) => {
		if (consentId) {
			setIsInformationSent(true)
			setModal('')
		}
	}

	const toggleModal = (value: string) => {
		setModal(value)
	}

	const getRequestType = () => {
		let requestType = pageData.requests.find(
			(req) => req.requestId === request?.typeId
		)?.templateType

		if (!requestType) {
			requestType = pageData.requests.find(
				(req) => req.requestId === 'other-subject'
			)?.templateType
		}

		return requestType
	}

	const requestTitle: string = request
		? `${pageRequestDetailsUtils.getRequestTitle(pageData.requests, request)}`
		: ''

	const otherComments: string | undefined = request?.metadata.find(
		(meta) => meta.label === OtherCommentsLabel
	)?.value

	const isTNRequest = (): boolean => {
		return (
			getRequestType()! === REQUEST_TYPE.TN ||
			getRequestType()! === REQUEST_TYPE.TN_UR
		)
	}

	// TODO: Change title using strapi
	const sectionTile = `${request?.code} - ${requestTitle}`

	const formFactory = {
		[REQUEST_TYPE.TN]: TNRequestDetails,
		[REQUEST_TYPE.TN_UR]: TNRequestDetails,
		[REQUEST_TYPE.PLAIN]: PlainRequestDetails
	}

	const ComponentForm = formFactory[getRequestType()!]

	if (!ComponentForm) {
		// @TODO: Afficher un message d'Erreur
		return <></>
	}

	return (
		<div className={pageRequestDetailsUtils.classes.modalContent}>
			<PageTitle
				title={
					requestFound
						? sectionTile
						: pageData?.assets?.page_myRequest_no_request_found
				}
			/>
			{requestFound && <SectionTitle title={sectionTile} />}
			<section
				className={joinClasses([
					pageUtils.classes.section,
					isTNRequest() ? pageUtils.classes.tnMargin : ''
				])}
			>
				{requestFound ? (
					<ComponentForm
						request={request!}
						onToggleModal={toggleModal}
						onInformationAdded={onInformationAdded}
						isModalOpen={modal}
						onCloseModal={() => setModal('')}
						onAddNewConsent={onAddNewConsent}
						consentList={consentList}
						consentClassWrapper={pageRequestDetailsUtils.classes.block1}
					>
						<RequestInfo
							request={request!}
							consentList={consentList}
							strapiRequest={strapiRequest}
							requestTitle={requestTitle}
							onAddNewConsent={onAddNewConsent}
							otherComments={otherComments}
							isOldRequest={isOldRequest}
						/>
					</ComponentForm>
				) : (
					<Notification
						type="error"
						text={pageData?.assets?.page_myRequest_no_request_found}
					/>
				)}
			</section>
		</div>
	)
}

export default RequestDetails
